import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import _ from 'lodash'

// Components
import { ContentDefault, TextLink } from 'components/Elements'

// Style
import './Events.scss'

import dateIcon from 'img/dateicon.svg'
import dateTimeIcon from 'img/datetimeicon.svg'
import standNumberIcon from 'img/standnumbericon.svg'

const Events = () => {
  const {
    events: { edges: eventsList },
  } = useStaticQuery(graphql`
    {
      events: allWordpressPage(filter: { wordpress_id: { eq: 30 } }) {
        edges {
          node {
            acf {
              events {
                title_event
                location_event
                date_event_start
                date_event_end
                datetime_event
                standnumber_event
                info_event
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="col-lg-12 events d-flex">
        {_.map(eventsList, (item, index) => (
          <div key={index} className="row">
            {_.map(item.node.acf.events, (eventItem, eventIndex) => (
              <div key={eventIndex} className="col-12 pl-0 events-column">
                <h4 className="events-title text-uppercase position-relative">
                  <span>{eventItem.title_event}</span>
                </h4>
                <ContentDefault>
                  <p>
                    <img src={dateIcon} width="26" alt="" />
                    {eventItem.date_event_start} 
                    {' '}
                    <span>until</span>
                    {' '}
                    {eventItem.date_event_end}
                  </p>
                  <p>
                    <img src={dateTimeIcon} width="26" alt="" />
                    {eventItem.datetime_event}
                  </p>
                  <p className="content-standnumber">
                    <img src={standNumberIcon} width="26" alt="" />
                    {eventItem.standnumber_event}
                  </p>
                </ContentDefault>
                <div className="col-lg-12 pt-4 px-0 events-text">
                  <div dangerouslySetInnerHTML={{ __html: eventItem.info_event }} />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  )
}

export default Events
