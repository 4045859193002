import React from 'react'
import { graphql } from 'gatsby'

import Home from '../templates/home'

const HomePage = ({ data }) => {
  return <Home data={data} />
}

export default HomePage

export const pageQuery = graphql`
  query {
    page: wordpressPage(wordpress_id: { eq: 12 }) {
      acf {
        bannerImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1240) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        bannerTitle: banner_title
        bannerButtonText: banner_button_text
        bannerButtonLocation: banner_button_location

        servicesTitle: services_title_home

        introTitle: intro_title
        introContent: intro_content
        
        eventsImage: events_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1240) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        eventsTitle: events_title
        eventsButtonText: events_button_text

        outroImage: outro_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1240) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        outroTitle: outro_title
        outroContent: outro_content
        outroButtonText: outro_button_text
        outroButtonLocation: outro_button_location
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
