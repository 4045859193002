import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// Components
import {
  TitleDefault,
  ButtonAlt,
  ContentDefault,
  BoxDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import ServicesOverview from 'components/Services/Overview'
import Events from 'components/Events'
import SEO from 'components/SEO'
import CustomLink from 'components/CustomLink'
import ParseContent from 'components/ParseContent'

import '../styles/templates/HomePage.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        bannerImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1240) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        bannerTitle: banner_title
        bannerButtonText: banner_button_text
        bannerButtonLocation: banner_button_location

        servicesTitle: services_title_home

        introTitle: intro_title
        introContent: intro_content
        
        eventsImage: events_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        eventsTitle: events_title
        eventsButtonText: events_button_text

        outroImage: outro_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1240) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        outroTitle: outro_title
        outroContent: outro_content
        outroButtonText: outro_button_text
        outroButtonLocation: outro_button_location
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

function HomePage({ data: { page } }) {
  return (
    <Layout>
      {page.acf.bannerImage && page.acf.bannerImage.localFile ? (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} image={page.acf.bannerImage.localFile.childImageSharp.fixed.src} article />
      ) : (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} />
      )}
      <div className="home-template">
        <section className="herohome position-relative d-flex align-items-center">
          <div className="container content">
            <div className="row py-4">
              <div className="col-lg-6">
                <TitleDefault className="mb-3">
                  {page.acf.bannerTitle}
                </TitleDefault>
                <div className="line mb-5" />
                <ButtonAlt to={page.acf.bannerButtonLocation}>{page.acf.bannerButtonText}</ButtonAlt>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
          <div className="herohome-image row position-absolute">
            <div className="col-12 col-lg-6 p-0 fill" />
            <div className="herohome-image-container col-lg-6 p-0">
              <BackgroundImage
                className="bgimage"
                fluid={page.acf.bannerImage.localFile.childImageSharp.fluid}
              />
            </div>
          </div>
        </section>
        <section className="services-home py-5 mb-lg-4">
          <div className="container">
            <div className="row">
              <TitleDefault className="text-uppercase">
                {page.acf.servicesTitle}
              </TitleDefault>
              <ServicesOverview />
            </div>
          </div>
        </section>
        <section className="whoweare home">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 d-none d-lg-block" />
              <div className="col-lg-6 px-2 text-center">
                <TitleDefault>{page.acf.introTitle}</TitleDefault>
                <ContentDefault>
                  <ParseContent content={page.acf.introContent} />
                </ContentDefault>
              </div>
              <div className="col-lg-3 d-none d-lg-block" />
            </div>
          </div>
        </section>
        <section className="events-home mt-5 position-relative">
          {/* <div className="col-12 text-center">
            <TitleDefault>{page.acf.eventsTitle}</TitleDefault>
          </div>
          <div className="events-home-image row position-absolute d-none d-lg-flex">
            <div className="events-home-image-container col-lg-6">
              <BackgroundImage
                fluid={
                  page.acf.eventsImage.localFile.childImageSharp.fluid
                }
              />
            </div>
            <div className="col-12 col-lg-6" />
          </div> */}
          <div className="container">
            <div className="row  py-4 py-lg-5">
              <div className="col-lg-5 pr-lg-4 text-lg-right">
                <Img
                  style={{ width: 200 }}
                  className="d-inline-block"
                  fluid={
                    page.acf.eventsImage.localFile.childImageSharp.fluid
                  }
                />
              </div>
              <div className="col-lg-7 pb-lg-3 pl-lg-4 text-left">
                <Events />
              </div>
            </div>
          </div>
        </section>
        <section className="contact-home position-relative">
          <div className="contact-home-image row position-absolute">
            <div className="col-12 col-lg-5" />
            <div className="contact-home-image-container col-lg-7">
              <BackgroundImage
                fluid={page.acf.outroImage.localFile.childImageSharp.fluid}
              />
            </div>
          </div>
          <div className="container">
            <div className="row py-lg-112 py-5 position-relative">
              <div className="col-lg-8 mx-auto mx-sm-none">
                <BoxDefault className="p-4">
                  <TitleDefault>{page.acf.outroTitle}</TitleDefault>
                  <div className="row align-items-center pl-4">
                    <div className="col-lg-7">
                      <ContentDefault>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: page.acf.outroContent,
                          }}
                        />
                      </ContentDefault>
                    </div>
                    <div className="col-lg-5">
                      <ButtonAlt to={page.acf.outroButtonLocation}>{page.acf.outroButtonText}</ButtonAlt>
                    </div>
                  </div>
                </BoxDefault>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomePage
